import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.14_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/illustrations/faulty-process.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/resized-logos/airtable-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/resized-logos/glide-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/resized-logos/n8n-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/resized-logos/openai-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/resized-logos/vapi-logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/features/CalButton.tsx");
