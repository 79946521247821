'use client';

import { Button } from '@/components/ui/button';
import { getCalApi } from '@calcom/embed-react';
import { useEffect } from 'react';

interface CalButtonProps {
	link: string;
	namespace: string;
	btnText?: string;
}

export default function CalButton({
	link,
	namespace,
	btnText = 'Book now',
}: CalButtonProps) {
	useEffect(() => {
		(async function () {
			const cal = await getCalApi({ namespace: namespace });
			cal('ui', {
				theme: 'light',
				cssVarsPerTheme: {
					light: {
						'cal-bg': '#ffffff',
						'cal-bg-default': '#989fc0',
						'cal-brand': '#8cf1ff',
						'cal-brand-text': '#01004b',
						'cal-brand-emphasis': '#eef3fb',
						'cal-brand-accent': '#8cf1ff',
						'cal-brand-subtle': '#549199',
						'cal-text': '#01004b',
						'cal-text-emphasis': '#01004b',
						'cal-border-emphasis': '#989fc0',
						'cal-text-error': 'red',
						'cal-border': '#989fc0',
						'cal-border-default': '#989fc0',
						'cal-border-subtle': '#989fc0',
						'cal-border-booker': '#989fc0',
						'cal-text-muted': '#ffffff',
						'cal-text-subtle': '#182067',
						'cal-bg-emphasis': '#eef3fb',
						'cal-bg-muted': '#8cf1ff',
						'cal-bg-subtle': '#eef3fb',
						'cal-border-booker-width': '3px',
					},
					dark: {},
					// 	dark: {
					// 		// 'cal-bg-default': '#01004b',
					// 		'cal-bg-default': '#01004b',
					// 		'cal-bg': '#182067',
					// 		// 	'cal-bg': '#8cf1ff',
					// 		// 	'cal-brand': '#01004b',
					// 		'cal-brand-text': '#ffffff',
					// 		'cal-brand-emphasis': '#eef3fb',
					// 		'cal-brand-accent': '#8cf1ff',
					// 		'cal-brand-subtle': '#549199',
					// 		// 	'cal-text': ' #182067',
					// 		// 	'cal-text-emphasis': '#01004b',
					// 		// 	'cal-border-emphasis': '#989fc0',
					// 		// 	'cal-text-error': 'red',
					// 		// 	'cal-border': '#989fc0',
					// 		'cal-border': '#989fc0',
					// 		// 	'cal-border-default': '#989fc0',
					// 		// 	'cal-border-subtle': '#989fc0',
					// 		// 	'cal-border-booker': '#989fc0',
					// 		// 	'cal-text-muted': '#ffffff',
					// 		// 	'cal-text-subtle': '#182067',
					// 		// 	'cal-bg-emphasis': '#ffffff',
					// 		//  'cal-bg-muted': '#8cf1ff',
					// 		'cal-bg-muted': '#01004b',
					// 		'cal-bg-subtle': '#01004b',
					// 		// 	'cal-border-booker-width': '3px',
					// 	},
				},
				hideEventTypeDetails: false,
				layout: 'month_view',
			});
		})();
	}, [namespace]);

	return (
		<Button
			className="text-base font-medium text-primary transition-all duration-300 hover:bg-white"
			data-cal-namespace={namespace}
			data-cal-link={`${link}/${namespace}`}
			data-cal-config='{"layout":"month_view"}'
			variant="default"
			size="lg"
		>
			{btnText}
		</Button>
	);
}
